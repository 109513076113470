<template>
<!--   큐레이션 의뢰 필터 -->
<!--    필터 + 모달 필터 레이아웃<br>-->
    <div class="search_filter_wrap">
      <div class="search_item_wrap creation_user">
        <div class="filter_category_box">
          <el-select id="category" v-model="searchOption" class="my_filter select"
                     popper-class="category_select"
                     :popper-append-to-body="false"
          >
            <el-option :value="'ina'" :label="$t('subject')"></el-option>
            <el-option :value="'hash'" :label="$t('hashtag')"></el-option>
            <el-option :value="'orni'" :label="$t('k_creator')"></el-option>
            <el-option :value="'prni'" :label="$t('owner')"></el-option>
            <el-option :value="'cuni'" :label="$t('curator')"></el-option>
          </el-select>
        </div>
        <div class="search_input">
          <el-input :placeholder="$t('st')" v-model="searchInput"
                    @input="setData('searchInput',searchInput)"
                    @keypress.enter="setFilterFunc(searchOption,searchInput)"/>
          <button class="search_btn" @click="setFilterFunc(searchOption,searchInput)"><span>{{$t('search')}}</span>
            <img src="@/assets/image/main/search_btn.png"></button>
        </div>
        <div class="end_filter">
<!--          <button class="filter_btn two" @click="openModal('member-filter-modal')"></button>-->
          <el-popover
              placement=""
              width="435"
              trigger="click"
              :open-delay="300"
              :offset="0"
              :append-to-body="false"
              :popper-options="{
                      boundariesElement: 'body',
                      gpuAcceleration: true,
                      positionFixed: true,
                      preventOverflow: true,
                    }">
            <el-button slot="reference" class="filter_btn two"></el-button>
            <div class="popup_tail"></div>
            <div class="popup_bg">
              <div class="popup_inner">
                <div class="inner_top"><span class="title">{{ $t('filter') }}</span></div>
                <div class="inner_middle">
                  <div class="sub_title">{{ $t('category') }}</div>
                  <div class="status_wrap">
                    <el-checkbox-group v-model="filterCategory"
                                       @change="setFilterFunc('ic', filterCategory)">
                      <el-checkbox :label="2">{{ $t('art') }}</el-checkbox>
                        <el-checkbox :label="178">{{ $t('prompt') }}</el-checkbox>
                      <el-checkbox :label="9">{{ $t('collections') }}</el-checkbox>
                      <el-checkbox :label="3">{{ $t('picture') }}</el-checkbox>
                      <el-checkbox :label="5">{{ $t('music') }}</el-checkbox>
<!--                      <el-checkbox :label="4">{{ $t('video') }}</el-checkbox>-->
                    </el-checkbox-group>
                  </div>
                  <div class="sub_title">{{ $t('status') }}</div>
                  <div class="edition_wrap">
                    <el-checkbox-group v-model="filterStatus"
                                       @change="setFilterFunc('imst', filterStatus)">
                      <el-checkbox :label="2">{{ $t('auction') }}</el-checkbox>
                      <el-checkbox :label="3">{{ $t('sale_license1') }}</el-checkbox>
                      <el-checkbox :label="5">{{ $t('fix_selling') }}</el-checkbox>
                    </el-checkbox-group>
                  </div>
                  <div class="sub_title">{{ $t('edition') }}</div>
                  <div class="edition_wrap">
                    <el-checkbox-group v-model="filterEdition"
                                       @change="setFilterFunc('st', filterEdition)">
                      <el-checkbox :label="0">{{ $t('edition') }}</el-checkbox>
                      <el-checkbox :label="1"> {{ $t('single') }}</el-checkbox>
                    </el-checkbox-group>
                  </div>
                  <div class="sub_title">{{ $t('type') }}</div>
                  <div class="type_wrap">
                    <el-checkbox-group v-model="filterType"
                                       @change="setFilterFunc('ed', filterType)">
                      <el-checkbox :label="1">{{ $t('digital') }}</el-checkbox>
                      <el-checkbox :label="0">{{ $t('real') }}</el-checkbox>
                    </el-checkbox-group>
                  </div>
                  <div class="sub_title">{{ $t('price2') }}</div>
                  <div class="price_wrap mb10">
                    <el-input class="el_price_input el_input_num" type="number"
                              v-model="priceInput[0]"></el-input>
                    <span> Mg</span>
                    <span class="first_input_step">~</span>
                    <el-input class="el_price_input el_input_num" type="number"
                              v-model="priceInput[1]"></el-input>
                    <span> Mg </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="wrapper_bottom" @click="changeFilter()">
              <button class="">{{ $t('apply2') }}</button>
            </div>
          </el-popover>
        </div>
        <div class="filter_sort_box">
          <el-select id="sort_select" v-model="sortOption" class="sort_filter select"
                     popper-class="category_select"
                     :popper-append-to-body="false"
                     @change="sortChange(sortOption)">
            <el-option :value="'last_update'" :label="$t('b_update')"></el-option>
            <el-option :value="'im_real_hit'" :label="$t('b_enter_m')"></el-option>
            <el-option :value="'im_like'" :label="$t('b_like')"></el-option>
            <el-option :value="'priceDesc'" :label="$t('priceDesc')"></el-option>
            <el-option :value="'priceAsc'" :label="$t('priceAsc')"></el-option>
          </el-select>
        </div>
      </div>
    </div>
</template>

<script>
export default {
  name: "ItemFilterCreationUserLayout",
  mixins: [],
  components: {
  },
  inject: [],
  provide() {
    return{

    }
  },
  props: {
  },
  data() {
    return{
      searchMsg:'',
      searchOption: 'ina',
      sortOption: 'last_update',
      searchInput: '',
      filterCategory: [],
      filterStatus: [],
      filterEdition: [],
      filterType: [],
      priceInput: ['', ''],
    }
  },
  beforeRouterEnter() {},
  created() {
  },
  mounted() {

  },
  beforeDestroy() {
  },
  destroyed() {},
  computed: {
  },
  methods:{
    openModal(name){
      this.$modal.show(name, {modalLayout: 'ModalFilterDefaultLayout'});
    },
    searchSubmit() {
      // 검색 입력 후 ,
      this.searchCheck = true;
      this.$router.push('/curationrequest');
    },
    sortChange(data) {
      if (data === 'priceAsc') {
        this.setFilterFunc('obt', 'i_price');
        this.setFilterFunc('obs', 'asc');
      } else if (data === 'priceDesc') {
        this.setFilterFunc('obt', 'i_price');
        this.setFilterFunc('obs', 'desc');
      } else {
        this.setFilterFunc('obt', data);
        this.setFilterFunc('obs', 'desc');
      }
    },
    setFilterFunc(key, value) {
      this.setFilter(key, value);
    },
    setData(key, value) {
      this[key] = value;
    },
    changeCheck(dataName) {
      this[dataName] = !this[dataName];
      if(this[dataName] ) {
        this[dataName] = true;
      } else {
        this[dataName] = false;
      }
      if(this[dataName]) {
        this.btnCheck = false;
      }
    },
    setterFilterDataInData() {
      let classnames = Object.keys(this.filterData);
      classnames.forEach(classname => {
        let obj = this.filterData[classname];
        let type = filterConfig[obj.key].type;
        if (this.hasOwnProperty(classname)) {
          // this[classname] =
          if (type == 'query') {
            this[classname] = obj.query;
            if (this.hasOwnProperty(classname + 'Option')) {
              this[classname + 'Option'] = obj.key;
            }
          } else if (type === 'between') {
            this[classname] = [obj.start, obj.end];
          } else if (type === 'match') {
            if (isNaN(obj.value)) {
              this[classname] = obj.value;
            } else {
              this[classname] = Number(obj.value);
            }

          } else if (type === 'terms') {
            this[classname] = obj.terms;
          }

        }
      })
    },
  },
  watch: {

  },
}
</script>

<style scoped>

</style>
